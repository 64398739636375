import { useTranslation } from '@pancakeswap/localization'
import { Button, Text, useMatchBreakpoints, Box, OpenNewIcon } from '@pancakeswap/uikit'
import Image from 'next/legacy/image'
import styled from 'styled-components'
import * as S from './Styled'
import { CustomHeading } from 'views/Swap/styles'

const ETHBunny = '/images/NewLogoQomSwap.png'
const ETHXPancakeSwap = '/images/NewLogoQomswap.png'

const RightWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: -10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    right: 1px;
    bottom: -18px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    right: 0px;
    bottom: -21px;
  }
`

const Title = styled.div`
  font-family: 'MyCustomFont', 'Kanit', 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 110%;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 21px;
  margin-top: 16px;

  @media screen and (max-width: 375px) {
    font-size: 21px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 35px;
    margin-top: 10px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 40px;
  }
`

const EthBanner = () => {
  const { t } = useTranslation()
  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints()
  return (
    <>
      <CustomHeading />
      <S.Wrapper
        style={{
          background: `linear-gradient(128.04deg, #7ca2fc -23.79%, #0032a8 121.4%)`,
          overflow: isMobile ? 'hidden' : 'visible',
        }}
      >
        <S.Inner>
          <S.LeftWrapper>
            <Box marginTop="3px">
              {/* <Image src={ETHXPancakeSwap} alt="Logo" width={119} height={18} unoptimized /> */}
              {isMobile && (
                <Title style={{ fontSize: '22px' }}>
                  {t('Follow us on')}
                  <br />
                  {t('X platform ❤️!!')}
                  <br />
                </Title>
              )}
              {isTablet && <Title style={{ fontSize: '22px' }}>{t('Follow us on X platform ❤️!!')}</Title>}
              {isDesktop && <Title>{t('Follow us on X platform ❤️!!')}</Title>}
            </Box>
            <Button
              minHeight="48px"
              onClick={() => window?.open('https://x.com/qomswap1', '_blank', 'noopener noreferrer')}
            >
              <Text color="invertedContrast" bold fontSize="16px" mr="4px">
                🦅 {t('Follow us on X')}
              </Text>
              <OpenNewIcon color="invertedContrast" />
            </Button>
          </S.LeftWrapper>
          <RightWrapper>
            <Image
              src={ETHBunny}
              alt="swapimage"
              width={isMobile ? 200 : 230}
              height={isMobile ? 210 : 231}
              unoptimized
            />
          </RightWrapper>
        </S.Inner>
      </S.Wrapper>
    </>
  )
}

export default EthBanner
