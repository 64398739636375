import shuffle from 'lodash/shuffle'
import { ReactElement, useMemo } from 'react'
import QomBanner from '../QomBanner'
import TelegramBanner from '../TelegramBanner'
import AirdropBanner from '../AirdropBanner'
import LockerBanner from '../LockerBanner'
import FarmsBanner from '../FarmsBanner'

interface IBannerConfig {
  shouldRender: boolean
  banner: ReactElement
}

/**
 * make your custom hook to control should render specific banner or not
 * add new campaign banner easily
 *
 * @example
 * ```ts
 *  {
 *    shouldRender: isRenderIFOBanner,
 *    banner: <IFOBanner />,
 *  },
 * ```
 */

export const useMultipleBannerConfig = () => {
  //   const isRenderCompetitionBanner = useIsRenderCompetitionBanner()
  //   const isRenderUserBanner = useIsRenderUserBanner()
  //   const shouldRenderIfoBanner = useIsRenderIfoBanner()

  return useMemo(
    () => {
      const NO_SHUFFLE_BANNERS: IBannerConfig[] = [
        {
          shouldRender: true,
          banner: <FarmsBanner />,
        },
        {
          shouldRender: true,
          banner: <QomBanner />,
        },
        //   { shouldRender: shouldRenderIfoBanner, banner: <ListaIFOBanner /> },
        {
          shouldRender: true,
          banner: <TelegramBanner />,
        },
        {
          shouldRender: true,
          banner: <AirdropBanner />,
        },
        {
          shouldRender: true,
          banner: <LockerBanner />,
        },

        //   {
        //     shouldRender: true,
        //     banner: <MultiChainBanner />,
        //   },
        //   {
        //     shouldRender: true,
        //     banner: <OptionsBanner />,
        //   },
        //   { shouldRender: true, banner: <VeCakeBanner /> },
        //   {
        //     shouldRender: true,
        //     banner: <V4InfoBanner />,
        //   },
        //   {
        //     shouldRender: true,
        //     banner: <NemesisDownfallBanner />,
        //   },
        //   {
        //     shouldRender: true,
        //     banner: <TopTraderBanner />,
        //   },
      ]

      const SHUFFLE_BANNERS: IBannerConfig[] = [
        //   { shouldRender: true, banner: <GalxeTraverseBanner /> },
        //   { shouldRender: true, banner: <WebNotificationBanner /> },
        //   { shouldRender: true, banner: <GameBanner /> },
        //   {
        //     shouldRender: isRenderCompetitionBanner,
        //     banner: <CompetitionBanner />,
        //   },
        //   {
        //     shouldRender: true,
        //     banner: <PerpetualBanner />,
        //   },
      ]
      return [
        ...NO_SHUFFLE_BANNERS,
        ...shuffle(SHUFFLE_BANNERS),
        // {
        //   // be the last one if harvest value is zero
        //   shouldRender: true,
        //   banner: <QomBanner />,
        // },
      ]
        .filter((bannerConfig: IBannerConfig) => bannerConfig.shouldRender)
        .map((bannerConfig: IBannerConfig) => bannerConfig.banner)
    },
    [
      // shouldRenderIfoBanner,
      // isRenderCompetitionBanner,
      // isRenderUserBanner.isEarningsBusdZero,
      // isRenderUserBanner.shouldRender,
    ],
  )
}
