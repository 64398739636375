import { GetStaticProps } from 'next'
import { gql, GraphQLClient } from 'graphql-request'
import { SWRConfig } from 'swr'
import Home from '../views/Home'
import Swap from 'views/Swap'
import { SwapFeaturesProvider } from 'views/Swap/SwapFeaturesContext'
import { CHAIN_IDS } from 'utils/wagmi'

const GRAPHQL_ENDPOINT = 'https://subgraph.qomswap.com/subgraphs/name/test/exchange'

const graphQLClient = new GraphQLClient(GRAPHQL_ENDPOINT)

const GET_UNIQUE_USERS_QUERY = gql`
  query GetUniqueUsers {
    swaps {
      from
    }
    mints {
      to
    }
    burns {
      to
    }
  }
`

export const getStaticProps: GetStaticProps = async () => {
  const results = {
    totalTx30Days: 0,
    tvl: 0,
    uniqueUsers: 0,
  }

  try {
    const userData = await graphQLClient.request(GET_UNIQUE_USERS_QUERY)

    const allAddresses = [
      ...userData.swaps.map((swap: { from: string }) => swap.from),
      ...userData.mints.map((mint: { to: string }) => mint.to),
      ...userData.burns.map((burn: { to: string }) => burn.to),
    ]

    const uniqueUsers = new Set(allAddresses).size

    results.uniqueUsers = uniqueUsers

    const totalTxAndTvlData = await graphQLClient.request(
      `
      query GetTotalTransactionsAndTVL($id: ID!) {
        pancakeFactory(id: $id) {
          totalTransactions
          totalLiquidityUSD
        }
      }
    `,
      {
        id: '0x475f361993d6facbf32fb82b66be0fb40f3d974c',
      },
    )

    if (totalTxAndTvlData?.pancakeFactory) {
      results.totalTx30Days = totalTxAndTvlData.pancakeFactory.totalTransactions || 0
      results.tvl = totalTxAndTvlData.pancakeFactory.totalLiquidityUSD || null
    }
  } catch (error) {
    console.error('Error fetching data from GraphQL API:', error)
  }

  return {
    props: results,
    revalidate: 60 * 60 * 24 * 30, // 30 days
  }
}

const IndexPage = ({ totalTx30Days, tvl, uniqueUsers }) => {
  return (
    <SWRConfig
      value={{
        fallback: {
          totalTx30Days,
          tvl,
          uniqueUsers,
        },
      }}
    >
      <SwapFeaturesProvider>
        <Home />
      </SwapFeaturesProvider>
    </SWRConfig>
  )
}

IndexPage.chains = CHAIN_IDS

export default IndexPage
